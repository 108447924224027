<template>
    <tbody>
        <tr
            :class="`${isDisabled(item) ? 'disabled' : ''}`"
            v-for="(item, index) in items"
            :key="index"
        >
            <td class="truncate">
                <span>{{ item.name }}</span>
            </td>
            <td class="truncate">
                <span>{{ item.lastname }}</span>
            </td>
            <td class="truncate">
                <span>{{ item.position }}</span>
            </td>
            <td class="truncate">
                <span>{{ item.phoneNumber }}</span>
            </td>
            <td class="truncate">
                <span>{{ item.email }}</span>
            </td>
            <td class="d-flex align-center justify-end">
                <!-- <v-btn icon v-if="isDelete()" small @click="onDelete(item)">
                    <v-icon color="red" small>mdi-delete</v-icon>
                </v-btn> -->
                <v-btn
                    icon
                    v-if="edit()"
                    @click="$emit('details', item)"
                    :action="'edit'"
                >
                    <v-icon size="20" color="secondary">mdi-pencil</v-icon>
                </v-btn>
            </td>
        </tr>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main"><ConfirmContent /></div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="onDeleteItem()"
                        >{{ $t('global:remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </tbody>
</template>
<script>
import { GlobalPermissions } from '../../../../../../plugins/constsTypes'
import ConfirmContent from '../../../../Departments/ConfirmModal/ConfirmContent.vue'
import { mapActions, mapMutations } from 'vuex'

export default {
    props: ['items'],
    components: {
        ConfirmContent,
    },
    data() {
        return {
            openConfirm: false,
            deletedItem: '',
        }
    },
    methods: {
        ...mapActions(['updateRepresentative']),
        ...mapMutations(['setRepresentativeModal']),
        onDelete(item) {
            this.deletedItem = item
            this.openConfirm = true
        },
        async onDeleteItem() {
            this.setRepresentativeModal({
                ...this.deletedItem,
                isDeleted: true,
                isDisabled: true,
            })
            await this.updateRepresentative({
                contactId: this.$route.params.id,
            })
            this.deletedItem = ''
            this.openConfirm = false
        },
        edit() {
            return this.checkPermissions(GlobalPermissions.update)
        },
        isDisabled(item) {
            return item.isDisabled
        },
        isDelete() {
            return this.checkPermissions(GlobalPermissions.delete)
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
    },
}
</script>

<template>
    <div>
        <Modal
            :title="$t('global:addition')"
            :open="open"
            v-on:close="closeModal"
        >
            <AEContent slot="AEContent" />
            <Buttons
                slot="buttons"
                :action="'create'"
                @closeModal="open = false"
            />
        </Modal>
        <div class="d-flex justify-end buttons--details">
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        data-cy="addEntry"
                        fab
                        dark
                        v-on="on"
                        small
                        v-if="add()"
                        @click="
                            open = true
                            clearRepresentsativeModal()
                        "
                        class="buttons--add margin--1"
                    >
                        <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('addRepresentative') }}</span>
            </v-tooltip>
        </div>
    </div>
</template>
<script>
import Buttons from './../Modal/Buttons'
import AEContent from './../Modal/Content'
import { mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['contacts', 'global'] },

    data() {
        return {
            open: false,
        }
    },
    methods: {
        ...mapMutations([
            'clearRepresentsativeModal',
            'clearRepresentativesErrors',
        ]),
        closeModal() {
            this.open = false
            this.clearRepresentativesErrors()
        },
        add() {
            return this.checkPermissions('CREATE')
        },
    },
    components: {
        Buttons,
        AEContent,
    },
}
</script>

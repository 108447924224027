<template>
    <div>
        <TableDetails
            :headers="headers"
            :length="getBranchesTable.pages"
            :page="getBranchesTable.page"
            @sort="setSort"
            @search="search"
            @change-page="setPage"
        >
            <Columns
                slot="columns"
                :items="getBranchesTable.items"
                @update-branch="openModal"
                @view-map="openMap"
            />
        </TableDetails>
        <Buttons @create-branch="openModal" />

        <Modal
            :title="
                modal.type === 'create'
                    ? $t('global:addition')
                    : $t('global:edit')
            "
            :open="modal.open"
            @close="closeModal"
        >
            <AEContent :action="modal.type" slot="AEContent" />
            <ModalButtons
                slot="buttons"
                :action="modal.type"
                @closeModal="closeModal"
            />
        </Modal>
        <Map
            :open="map.open"
            @close="map.open = false"
            :coords="map.coords"
            :zoom="19"
        />
    </div>
</template>

<script>
import Columns from './Details/Columns'
import Buttons from './Details/Buttons'
import AEContent from './Modal/Content'
import ModalButtons from './Modal/Buttons'
import Map from './../../../../Global/GoogleMap/Map'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { GlobalActions } from '../../../../../plugins/constsTypes'
export default {
    i18nOptions: { namespaces: ['contacts'] },
    data() {
        return {
            modal: {
                type: '',
                open: false,
            },
            map: {
                coords: {},
                open: false,
            },
            open: false,
            // map: false,
            headers: [
                {
                    text: this.$t('contacts:branchName'),
                    value: 'branchName',
                    width: '20%',
                },
                {
                    text: this.$t('contacts:address'),
                    value: 'address',
                    width: '15%',
                },
                {
                    text: this.$t('contacts:city'),
                    value: 'city',
                    width: '15%',
                },
             
                {
                    text: this.$t('contacts:phoneNumber'),
                    value: 'phoneNumber',
                    width: '10%',
                },
              ,
                {
                    text: this.$t('contacts:email'),
                    value: 'email',
                    width: '15%',
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '6%',
                    sortable: false,
                },
            ],
        }
    },

    components: {
        Columns,
        Buttons,
        AEContent,
        Map,
        ModalButtons,
    },
    computed: {
        ...mapGetters(['getBranchesTable']),
    },
    methods: {
        ...mapActions(['fetchBranchesTable']),
        ...mapMutations([
            'clearBranchErrors',
            'clearBranchModal',
            'setBranchModal',
            'setBranchesTable',
        ]),
        closeModal() {
            this.clearBranchErrors()
            this.clearBranchModal()
            this.modal.open = false
        },
        openModal(type, item) {
            if (type === GlobalActions.update) {
                this.setBranchModal(item)
            }
            this.modal.type = type
            this.modal.open = true
        },
        openMap(location) {
            this.map.coords = location
            this.map.open = true
        },
        search(value) {          
            this.setBranchesTable({ search: value, page: 1 })
            this.fetchBranchesTable({ id: this.$route.params.id })
        },
        setSort(sort) {
            this.setBranchesTable({ sort })
            this.fetchBranchesTable({ id: this.$route.params.id })
        },
        setPage(page) {
            this.setBranchesTable({ page })
            this.fetchBranchesTable({ id: this.$route.params.id })
        },
    },
}
</script>

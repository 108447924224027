<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0"
                        >
                            {{ getContactDetails.name }}
                        </p>
                        <span class="mb-5">{{ getContactDetails.city }}</span>
                        <span
                            ><b>{{ `${$t('contacts:email')}` }}:</b>
                            {{ getContactDetails.email }}</span
                        >
                        <span
                            ><b>{{ `${$t('contacts:phoneNumber')}` }}:</b>
                            {{ getContactDetails.phoneNumber }}</span
                        >
                        <span class="mt-5"
                            ><b>{{ `${$t('contacts:clientGuardian')}` }}:</b>
                            {{
                                `${$get(
                                    getContactDetails,
                                    'clientGuardian.name',
                                    ''
                                )} ${$get(
                                    getContactDetails,
                                    'clientGuardian.lastname',
                                    ''
                                )}`
                            }}
                        </span>
                        <span class="mt-5"
                        data-cy-details="website">
                            {{ getContactDetails.website }}
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" md="12" class="pt-0">
                        <Separator :text="$t('contacts:contactDetails')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="12" md="12">
                        <v-text-field
                            data-cy-details="fullName"
                            readonly
                            :value="getContactDetails.name"
                            :label="$t('contacts:fullName')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="12" md="6">
                        <v-text-field
                            data-cy-details="shortName"
                            :value="getContactDetails.shortName"
                            readonly
                            :label="$t('contacts:shortName')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="3">
                        <v-text-field
                            data-cy-details="contactID"
                            readonly
                            :value="getContactDetails.contactId"
                            :label="$t('contacts:contactID')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="3">
                        <v-text-field
                            data-cy-details="TIN"
                            readonly
                            :value="getContactDetails.NIP"
                            :label="$t('contacts:nip')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <Separator :text="$t('contacts:contactsDetails')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="12" md="4">
                        <v-text-field
                            data-cy-details="street"
                            readonly
                            :value="getContactDetails.street"
                            :label="$t('contacts:street')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="4">
                        <v-text-field
                            data-cy-details="houseNumber"
                            readonly
                            :value="getContactDetails.houseNumber"
                            :label="$t('contacts:houseNumber')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="4">
                        <v-text-field
                            data-cy-details="appartmentNumber"
                            readonly
                            :value="getContactDetails.apartmentNumber"
                            :label="$t('contacts:flatNumber')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="12" md="4">
                        <v-text-field
                            data-cy-details="city"
                            readonly
                            :value="getContactDetails.city"
                            :label="$t('contacts:city')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="2">
                        <v-text-field
                            data-cy-details="postCode"
                            readonly
                            :value="getContactDetails.postCode"
                            :label="$t('contacts:postCode')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="2">
                        <v-text-field
                            readonly
                            data-cy-details="postAddress"
                            :value="getContactDetails.post"
                            :label="$t('contacts:post')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="4">
                        <v-text-field
                            data-cy-details="country"
                            readonly
                            :value="getContactDetails.state"
                            :label="$t('contacts:state')"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-1" cols="12" md="4">
                        <v-text-field
                            data-cy-details="email"
                            readonly
                            :value="getContactDetails.email"
                            :label="$t('contacts:email')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" md="4">
                        <v-text-field
                            data-cy-details="phoneNumber"
                            readonly
                            :value="getContactDetails.phoneNumber"
                            :label="$t('contacts:phoneNumber')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <Separator :text="$t('contacts:other')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0" cols="12" md="4">
                        <v-text-field
                            data-cy-details="contactType"
                            readonly
                            :value="
                                $get(
                                    getContactDetails,
                                    $t(
                                        `contacts:${getContactDetails.contactType}`
                                    ),
                                    ''
                                )
                            "
                            :label="$t('contacts:contactType')"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="12" md="4">
                        <v-text-field
                            data-cy-details="accountManager"
                            readonly
                            :value="`${$get(
                                getContactDetails,
                                'clientGuardian.name',
                                ''
                            )} ${$get(
                                getContactDetails,
                                'clientGuardian.lastname',
                                ''
                            )}`"
                            :label="$t('contacts:clientGuardian')"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['contacts'] },
    computed: {
        ...mapGetters(['getContactDetails']),
    },
}
</script>

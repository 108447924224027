<template>
    <div class="d-flex justify-end buttons--details">
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    data-cy="addEntry"
                    fab
                    v-on="on"
                    small
                    v-if="add()"
                    @click="$emit('create-branch', 'create')"
                    class="buttons--add margin--1"
                >
                    <v-icon small>mdi-home-plus</v-icon>
                </v-btn>
            </template>
            <span>Dodaj oddział</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    i18nOptions: { namespaces: ['global'] },
    methods: {
        add() {
            return this.checkPermissions('CREATE')
        },
    },
}
</script>

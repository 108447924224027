<template>
    <tbody>
        <tr
            :class="`${isDisabled(item) ? 'disabled' : ''}`"
            v-for="(item, index) in items"
            :key="index"
        >
            <td class="truncate">
                <span>{{ item.name }}</span>
            </td>
            <td class="truncate">
                <span>{{ item.address }}</span>
            </td>
            <td class="truncate">
                <span>{{ item.city  }}</span>
            </td>
            
            <td class="truncate">
                <span>{{ item.phoneNumber }}</span>
            </td>
            <td class="truncate">
                <span>{{ item.email }}</span>
            </td>
            <td class="d-flex align-center justify-end">
                <!-- <v-btn icon v-if="isDelete()" small @click="onDelete(item)">
                    <v-icon color="red" small>mdi-delete</v-icon>
                </v-btn> -->
                <v-btn
                    icon
                    small
                    @click="$emit('view-map', item.location)"
                    v-if="item.location"
                >
                    <v-icon small>mdi-map-marker</v-icon>
                </v-btn>

                <!-- ADDED NOT TO MODIFY IF - "SIEDZBA GŁÓWNA" -->
                <v-btn
                    icon
                    v-if="edit() && item.name !== 'Siedziba główna'"
                    small
                    @click="$emit('update-branch', GlobalActions.update, item)"
                >
                    <v-icon color="secondary" small>mdi-pencil</v-icon>
                </v-btn>
            </td>
        </tr>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main"><ConfirmContent /></div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="onDeleteItem()"
                        >{{ $t('global:remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </tbody>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import {
    GlobalPermissions,
    GlobalActions,
} from '../../../../../../plugins/constsTypes'

import ConfirmContent from '../../../../Departments/ConfirmModal/ConfirmContent.vue'
export default {
    props: ['items'],
    components: {
        ConfirmContent,
    },
    data() {
        return {
            openConfirm: false,

            deletedItem: '',
            GlobalActions: GlobalActions,
        }
    },
    methods: {
        ...mapActions(['updateBranch']),
        ...mapMutations(['setBranchModal']),
        onDelete(item) {
            this.deletedItem = item
            this.openConfirm = true
        },
        async onDeleteItem() {
            this.setBranchModal({
                ...this.deletedItem,
                isDeleted: true,
                isDisabled: true,
            })
            await this.updateBranch({ contactId: this.$route.params.id })
            this.deletedItem = ''
            this.openConfirm = false
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        edit() {
            return this.checkPermissions(GlobalPermissions.update)
        },
        isDisabled(item) {
            return item.isDisabled
        },
        isDelete() {
            return this.checkPermissions(GlobalPermissions.delete)
        },
    },
}
</script>

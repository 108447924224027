<template>
    <div>
        <TableDetails
            @sort="setSort"
            @search="search"
            :headers="headers"
            :page="getRepresentativesTable.page"
            :items="getRepresentativesTable.items"
            :length="getRepresentativesTable.pages"
            @change-page="setPage"
        >
            <Columns
                @details="editRepresentative"
                slot="columns"
                :items="getRepresentativesTable.items"
            />
        </TableDetails>
        <Buttons />
        <Modal height="635" :title="$t('global:edit')" :open="open" v-on:close="closeModal">
            <AEContent :action="'edit'" slot="AEContent" />
            <ModalButtons
                slot="buttons"
                :action="'edit'"
                @closeModal="open = false"
            />
        </Modal>
    </div>
</template>
<script></script>
<script>
import Columns from './Details/Columns'
import Buttons from './../Representatives/Details/Buttons'
import AEContent from './../Representatives/Modal/Content'
import ModalButtons from './../Representatives/Modal/Buttons'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['contacts'] },
    computed: {
        ...mapGetters(['getRepresentativesTable']),
    },
    methods: {
        ...mapMutations([
            'setRepresentativeModal',
            'setRepresentativesTable',
            'clearRepresentativesErrors',
        ]),
        ...mapActions(['fetchRepresentativesTable']),
        editRepresentative(item) {
            this.setRepresentativeModal(item)
            this.open = true
        },
        closeModal() {
            this.clearRepresentativesErrors()
            this.open = false
        },
        search(value) {
            this.setRepresentativesTable({ search: value, page: 1 })
            this.fetchRepresentativesTable({ id: this.$route.params.id })
        },
        setSort(sort) {
            this.setRepresentativesTable({ sort })
            this.fetchRepresentativesTable({ id: this.$route.params.id })
        },
        setPage(page) {
            this.setRepresentativesTable({ page })
            this.fetchRepresentativesTable({ id: this.$route.params.id })
        },
    },
    data() {
        return {
            open: false,
            length: 10,
            headers: [
                {
                    text: this.$t('contacts:name'),
                    value: 'name',
                    width: '20%',
                },
                {
                    text: this.$t('contacts:lastname'),
                    value: 'lastname',
                    width: '20%',
                },
                {
                    text: this.$t('contacts:position'),
                    value: 'position',
                    width: '25%',
                },
                {
                    text: this.$t('contacts:phoneNumber'),
                    value: 'phoneNumber',
                    width: '10%',
                },
                {
                    text: this.$t('contacts:email'),
                    value: 'email',
                    width: '15%',
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '6%',
                    sortable: false,
                },
            ],
            items: [],
        }
    },

    components: {
        Columns,
        Buttons,
        AEContent,
        ModalButtons,
    },
}
</script>

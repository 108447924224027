<template>
    <v-container>
        <Header
            :registryTitle="getContactsRegistry.name"
            :registryUrl="'contacts'"
            :registryStatus="getContactStatuses"
            :actualStatus="getContactDetails.status"
            :recordIndex="getContactDetails.index"
            @status-changed="changeStatus"
            :avatarData="getContactDetails.createdBy"
        />
        <Main :items="items" class="p-relative">
            <v-tab-item
                v-for="item in items"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('global:edit')"
                    :open="open"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="openModal()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('contacts:editContact') }}</span>
                    </v-tooltip>
                </div>
            </div>
        </Main>
    </v-container>
</template>
<script>
import Branches from './../../../components/Registries/Contacts/Tabs/Branches/Branches'
import Information from './../../../components//Registries/Contacts/Details/Information'
import Representatives from './../../../components//Registries/Contacts/Tabs/Representatives/Representatives'
import Buttons from './../../../components/Registries/Contacts/Modal/Buttons'
import AEContent from './../../../components/Registries/Contacts/Modal/Content'
import store from './../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    i18nOptions: { namespaces: ['contacts', 'global'] },
    components: {
        Branches,
        Information,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getContactStatuses',
            'getContactDetails',
            'getContactsRegistry',
        ]),
    },
    methods: {
        closeModal() {
            this.open = false
            this.clearContactsErrors()
        },
        ...mapMutations(['setContactModal', 'clearContactsErrors']),
        ...mapActions(['updateContactStatus', 'fetchUsers']),
        changeStatus(status) {
            this.updateContactStatus({ status, id: this.getContactDetails._id })
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        async openModal() {
            await void this.fetchUsers({
                select: 'name lastname mail status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            this.open = true
            this.setContactModal()
        },
    },
    data: () => ({
        open: false,
        items: [
            {
                name: 'Oddziały',
                id: 'dodatkowa',
                icon: 'mdi-home-assistant',
                component: Branches,
            },
            {
                name: 'Reprezentanci',
                id: 'representatives',
                icon: 'mdi-account-group',
                component: Representatives,
            },
        ],
    }),
    beforeRouteEnter(to, from, next) {
        store
            .dispatch('fetchContact', { id: to.params.id })
            .then(() => {
                store.dispatch('fetchRepresentativesTable', {
                    id: to.params.id,
                })
            })
            .then(() => {
                store.dispatch('fetchBranchesTable', { id: to.params.id })
            })
            .then(() => next())
    },
    // CHANGE RIGHBOX ROUTE
    beforeRouteUpdate(to, from, next) {
        store
            .dispatch('fetchContact', { id: to.params.id })
            .then(() => {
                store.dispatch('fetchRepresentativesTable', {
                    id: to.params.id,
                })
            })
            .then(() => {
                store.dispatch('fetchBranchesTable', { id: to.params.id })
            })
            .then(() => next())
    },
}
</script>
